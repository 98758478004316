import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Header, HeaderSubheader, Image, Modal, ModalActions, ModalContent, ModalDescription } from "semantic-ui-react"
import partyPopper from 'src/assets/svg/svg_icons/party-popper.svg'
import { setFirstTimeOnApp, setShowWelcomeModal } from 'src/redux/actions/auth'

export default function WelcomeModal() {
    const dispatch = useDispatch();
    const { showWelcomeModal } = useSelector((state) => state.auth)
    const onClose = () => dispatch(setShowWelcomeModal(false))

    useEffect(() => {
      dispatch(setFirstTimeOnApp(false))
    }, [])
  
    return (
        <Modal
          open={showWelcomeModal}
          onClose={onClose}
          size={'medium'}
          dimmer={{ style: { zIndex: 9999 , backgroundColor: 'rgba(0,0,0,.6)'  , backdropFilter: 'blur(3px)'} }} 
        >
          <Header as="h3" icon={null} content={'¡Te damos la bienvenida a Pepper!'} />
          <ModalContent image>
            <Image size='small' src={partyPopper} wrapped /> 
            <ModalDescription>
              <Header>Disfruta tus 7 días gratis.</Header>
              <p style={{ fontSize: '11pt' }}>Te recomendamos partir conectando tus cuentas del SII y tus bancos para obtener el máximo provecho.</p>
              <p style={{ fontSize: '11pt' }}>Sólo tomará unos segundos.</p>
            </ModalDescription>
          </ModalContent>
          <ModalActions>
            <Button color='black' onClick={onClose}>
              Cerrar
            </Button>
            <Button
              content='Empecemos!'
              labelPosition='right'
              icon='checkmark'
              onClick={onClose}
              positive
            />
          </ModalActions>
          
        </Modal>
      )
    }