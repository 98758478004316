export const types = {
  set: "[UI] Sidebar Responsive",
  getForms: "[UI] Forms",
  getValuesLiquidez: "[LIQUIDEZ] Get Values from API",
  getFlujoCajaMensual: "[FLUJOCAJA] Get Values from API - Mensual",
  getFlujoCajaPasado: "[FLUJOCAJA] Get Past Values",
  getFlujoCajaTablaSemanal: "[FLUJOCAJA] Get Values from API - Semanal",
  getFinanciamientoCard: "[FLUJOCAJA] Get Values from API - Financiamiento",
  getBarCreditos: "[CREDITOS] Get Values Bar",
  getCreditosTable: "[CREDITOS] Get Values Table",
  getBarCreditosLine: "[CREDITOS] Get Values Bar Line",
  getBarDashboard: "[DASHBOARD] Get Values Bar",
  getBarDashboardLine: "[DASHBOARD] Get Values Bar Line",
  getLineDashboard: "[DASHBOARD] Get Values Line",
  getNotificacionesDashboard: "[DASHBOARD] Get Notificaciones",
  getCobranzaDashboard: "[DASHBOARD] Get Cobranzas",
  getPanelesDashboard: "[DASHBOARD] Get Paneles",
  authLogin: "[LOGIN] Login user",
  authFirstTime: "[LOGIN] First time on app",
  authShowWelcomeModal: "[LOGIN] Show welcome modal",
  authLogout: "[LOGIN] Logout user",
  setCurrency: "[CURRENCY] Set Currency",
  setTabId: "[TABS] Set tabId",
  getCurrency: "[CURRENCY] Get Currency",
  getCompanyList: "[COMPANY] Get Company List",
  setBarDashboardLine: "[DASHBOARD] Set Values Bar Line",
  getPanelAgenda: "[AGENDA] Get Values Panel",
  getCreditosVigentes: "[CREDITOS] Get Values Creditos Vigentes",
  getCreditosResumen: "[CREDITOS] Get Values Creditos Resumen",
  getDocTributarios: "[DOCTRIB] Get Values Table",
  getDataConexiones: "[CONEXIONES] Get Data",
  getDataProveedores: "[PROVEEDORES] Get Data",
  getInsertConexionBancaria: "[CONEXIONES] Get Data Insert Conexion Bancaria",
  getCobranzaApi: "[DASHBOARD] Get Data Cobranzas New Api",
  getGeneralTableData: "[GENERAL] Get General Table Data",
  selectedClient: "[DASHBOARD] Get selected client",
  selectedFilter: "[DASHBOARD] Get selected filter",
  selectedCompany: "[UI] Get selected company",
  getLoadingState: "[UI] Get loading state",
  getAlertState: "[UI] Get alert state",
  getUpdateNeeded: "[FLUJOCAJA] Get update",
  getFcDetail: "[FLUJOCAJA] Get Detail",
  getDelayedPoints: "[FLUJOCAJA] Get Delayed Points",
  getSettledPoints: "[FLUJOCAJA] Get Settled Points",
  getRegularPoints: "[FLUJOCAJA] Get Regular Points",
  getDisplayDetail: "[FLUJOCAJA] Get Display Detail",
  getAreaName: "[FLUJOCAJA] Get Area Name",
  getFcData: "[FLUJOCAJA] Get Flujocaja Data",
  getModal: "[SHOW OR HIDE MODAL] Get Modal",
  getManualDocument: "[MANUAL DOCUMENT] Get manual document",
  getSelectedDTEs: "[PROVEEDORES] Get selected DTEs",
  getExcludedProviders: "[PROVEEDORES] Get excluded providers",
  getBalances: "[PROVEEDORES] Get balances",
  getNominaVencida: "[PROVEEDORES] Get nomina vencida",
  getNominaSelected: "[PROVEEDORES] Get nomina selected",
  getNominaState: "[PROVEEDORES] get nomina state",
  getFilterNomina: "[PROVEEDORES] get nomina filters",
  getNominaFiltered: "[PROVEEDORES] get nomina filtered",
  getDocumentsFilter: "[DOCUMENTOS] get documents filters",
  sideBarMinimized: "[DASHBOARD] Get sideBar size",
  openSideBar: "[SIDEBAR] Open/Close Sidebar",
  initialBalanceSidebar: "[INITIAL BALANCE] Sidebar",
  cashFlowCategories: "[CASH FLOW] Categories",
  cashFlowAreas: "[CASH FLOW] Areas",
  getModalComponent: "[SHOW NEW MODAL COMPONENT] Get Modal",
  getCreditsDocuments: "[SHOW CREDITS DOCUMENTS] Get Credits Documents",
  getCredits: "[SHOW ALL CREDITS] Get all credits by company",
  getCreditsEvolution: "[SHOW EVOLUTION OF CREDITS] Get all credits evolution",
  getPaginatedUsers: "[USUARIOS] Get paginated Users.",
  getTotalUsers: "[USUARIOS] Get number of total Users.",
  getNotifications: "[NOTIFICACIONES] Get notifications from bots.",
  setNotifications: "[ACTUALIZAR NOTIFICACIONES] Update current notifications.",
  getTransactions: "[CONCILIACION] Get transactions.",
  getTransactionsTotalDocs: "[CONCILIACION] Total number of transactions.",
  getTransactionsBanks: "[CONCILIACION] Bank list of transactions.",
  getTransactionsFilter: "[CONCILIACION] Transactions filters",
  getOnboarding: "[ONBOARDING] Onboarding data",
  getSelectedDocuments: "[DOCUMENTOS] Selected documents",
  getEnhancedIcons: "[ENHANCED] Enhanced Icons",
  eerr: "[REPORTS] eerr",
  conciliationRatio: "[DASHBOARD] % de conciliacion",
  conciliationRatioUpdate: "[DASHBOARD] update % de conciliacion",
  detailEerr: "[Reports] detail EERR",
  documentTypes: "[DOCUMENTS] Document Types filter",
  selectedType: "[DOCUMENTS] Document selected Type filter",
  getExcludeDocumentCodes: "[DOCUMENTS] Documents excluded codes filter",
  getUpdateDocuments: "[DOCUMENTS] Update Documents",
  getOnboardingUserId: "[ONBOARDING] Update Onboarding user id",
  getOnboardingUserRut: "[ONBOARDING] Update Onboarding user rut",
  getOnboardingUserEmail: "[ONBOARDING] Update Onboarding user email",
  getOnboardingUserName: "[ONBOARDING] Update Onboarding user name",
  getOnboardingUserLastName: "[ONBOARDING] Update Onboarding user lastname",
  getOnboardingCompanyId: "[ONBOARDING] Update Onboarding company id",
  getOnboardingCompanyRut: "[ONBOARDING] Update Onboarding company rut",
  getOnboardingCompanyName: "[ONBOARDING] Update Onboarding company name",
  getOnboardingCompanyAlias: "[ONBOARDING] Update Onboarding company alias",
  getFlujoCajaCurrency: "[FLUJOCAJA] Get Cashflow Currency",
  getUpdateEERRNeeded: "[EERR] Update Needed",
  getUpdateDashboardNeeded: "[Dashboard] Update Needed",
  getUpdateCashflowNeeded: "[Cashflow] Update Needed",
  getUpdateBalanceNeeded: "[BALANCE] Update Needed",
  getUpdateCompanyNeeded: "[COMPANY] Update Needed",
  getUpdateNominaInProcessNeeded: "[PROVEEDORES] Update In Process Needed",
  getUpdateNominaProcessedNeeded: "[PROVEEDORES] Update Processed Needed",
  updateDocumentTypesFilter: "[DOCUMENTOS] Update documen types filter",
  updateProviders: "[PROVEEDORES] Update providers data.",
  updateCashFlow: "[FLUJO CAJA] Update cashflow data.",
  getUpdateAgendaNeeded: "[AGENDA] Update agenda",
  lastBotUpdateDateSii: "[DOCUMENTOS] Get last update date from bot sii",
  lastBankUpdated: "[BANKS] Get last update date from banks",
  updateConciliacion: "[CONCILIACION] Update conciliación data",
  getUpdatePasswordNeeded: "[PASSWORDS] Update Providers Needed",
  getInProcessNomina: "[PROVEEDORES] Get in process nominas",
  getProcessedNominas: "[PROVEEDORES] Get nominas processed",
  updateNominasFilter: "[PROVEEDORES] Update filter by label",
  updateNominasInProcess: "[PROVEEDORES] Update only in process Nominas",
  updateCashflowCollapsedCategories: "[CASHFLOW] Update collapsed categories",
  updateCashflowStructure: "[CASHFLOW] Update cashflow structure",
  updateCobranza: "[COBRANZA] Update cobranza",
  cleanSelectedDocuments: "[DOCUMENTOS] Clean selected documents",
  getUpdateEOAF:"[EOAF] Update EOAF"
};
