import moment from "moment";
import { types } from "../types/types";

const initialState = {
    getUpdateEOAF: { value: false }
};
export const eoafReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.getUpdateEOAF:
      return { ...state, getUpdateEOAF: action.payload }
    default:
      return state;
  }
};
